@import "../../../styles/theme.scss";

.Select {
  margin-top: 1rem;

  &--noLabel {
    margin-top: 0;
  }

  &__label {
    &.Mui-focused {
      margin-top: -0.5rem;
    }

    &.MuiInputLabel-shrink {
      margin-top: -0.5rem;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__special {
    border-top: 1px solid $grey500;
    padding-top: 1rem;
    bottom: -8px;
  }

  &__input {
    border-radius: $borderRadius;

    &--open {
      border-radius: $borderRadius $borderRadius 0 0;
    }

    .MuiSelect-icon {
      fill: $grey600;
      stroke: $grey600;
    }
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
  }

  &__valueOrder {
    margin: 9px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: $stoneBlue;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
